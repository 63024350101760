<template>
  <section class="monitor">
    <h3 class="subtitle" v-show="showQuestion || showResponse">
      <span class="subtitle-1">Customer </span>
      <span class="subtitle-2">{{ index > 3 ? 'return' : 'purchase' }}</span>
    </h3>
    <template v-if="showQuestion">
      <div class="title">
        <h2>{{ data.question }}</h2>
      </div>
      <div class="button-group">
        <button
          class="button"
          :class="{ correct: selected && index === data.correctAnswer }"
          v-for="(answer, index) in data.answers"
          :key="index"
          @click="handleClick(index)"
        >
          {{ answer }}
        </button>
      </div>
    </template>
    <template v-else-if="showResponse">
      <div v-if="isCorrectAnswer" class="response">
          <h3 class="response-title">Correct!</h3>
          <p class="response-text">{{ selectedResponse }}</p>
      </div>
      <div v-else-if="isWrongAnswer" class="response">
        <h3 class="response-title">Oops!</h3>
        <p class="response-text">
          The correct answer is: <span>{{ correctAnswer }}</span> {{ selectedResponse }}
        </p>
      </div>
      <button
        class="button md"
        @click="continueVideo"
      >
        Continue
      </button>
    </template>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MonitorQuestion',
  props: ['data', 'index', 'showQuestion', 'showResponse', 'selectedResponse'],
  computed: {
    ...mapGetters(['selectedAnswer']),
    selected: {
      get() {
        return this.$store.getters.selectedAnswer;
      },
      set(value) {
        this.$store.dispatch('setSelectedAnswer', value);
      },
    },
    isCorrectAnswer() {
      return this.selected && this.selected === this.data.correctAnswer;
    },
    isWrongAnswer() {
      return this.selected && this.selected !== this.data.correctAnswer;
    },
    correctAnswer() {
      return this.data.answers[this.data.correctAnswer];
    },
  },
  methods: {
    handleClick(index) {
      this.$emit('click', index);
    },
    continueVideo() {
      this.$emit('continue');
    },
  },
};
</script>

<style scoped>
.monitor {
  position: absolute;
  top: 11.35%;
  right: 6.1%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: calc((695 / 1435) * 100%);
  height: calc((457 / 958) * 100%);

  text-align: center;
  color: white;

  z-index: 2;
}

.subtitle {
  padding: 3rem 6rem;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;

  background: #00616D;

  font-family: "Helvetica Bold", sans-serif;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.subtitle-2 {
  color: #F2743F;
}

.title {
  position: relative;

  max-width: 80%;
}

.title h2 {
  font-size: 3.6rem;
  font-family: "Helvetica Bold", sans-serif;
  line-height: 3.7rem;
}

.button-group {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  width: 80%;
  height: calc((180 / 457) * 100%);

  margin-bottom: 3rem;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40%;
  border: 0;
  border-radius: 2rem;
  padding-inline: 2rem;

  background: #0F8891;

  font-family: "Helvetica Bold", sans-serif;
  font-size: 2.1rem;
  color: white;
  cursor: pointer;
}

.button:active {
  background: #167580;
}

.md {
  width: 40%;
  height: 17%;
  margin-bottom: 3rem;
}

.response-title {
  margin-bottom: 1rem;

  font-family: 'Helvetica Bold', sans-serif;
  font-weight: 700;
  font-size: 3.2rem;
  text-align: center;
}

.response-text {
  max-width: 64.2rem;
  padding-inline: 2rem;

  font-size: 2.7rem;
}

.response-text span {
  padding: 0.2rem 0.7rem;

  background: #F76B1C;

  font-family: 'Helvetica Bold', sans-serif;
  font-weight: 700;
}

.correct {
  background: #F2743F;
}
</style>
