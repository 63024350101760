<template>
  <main class="wrapper">
    <div class="container">
      <video
        v-show="showVideo1"
        :src="currentVideo"
        class="video"
        muted
        ref="video1"
        @ended="onVideoEnded"
      />
      <video
        v-show="showVideo2"
        :src="nextVideo"
        class="video"
        muted
        ref="video2"
        @ended="onVideoEnded"
      />
      <transition name="fade">
        <MonitorQuestion
          v-if="(showQuestion || showResponse) && !isLastVideo"
          :showQuestion="showQuestion"
          :showResponse="showResponse"
          :data="currentQuestion"
          :index="videoIndex1"
          :selectedResponse="selectedResponse"
          @click="chooseAnswer"
          @continue="continueVideo"
        />
      </transition>
    </div>
    <SectionLayout
      v-show="isLastVideo"
      class="final"
      text="Through new returns protocols, with a focus on customer services,
          we'll be able to reduce shrink dollars while making the process
          more seamless for our customers."
      title="Returns Process: Rebooted!"
      btnText="Exit"
      @btn-action="exitHandler"
    />
  </main>
</template>

<script>
import { mapGetters } from 'vuex';
import MonitorQuestion from '../components/MonitorQuestion';
import SectionLayout from '../components/SectionLayout';
import video1 from '../assets/videos/Cust1_vid1.mp4';
import video2 from '../assets/videos/Cust1_vid2.mp4';
import video3 from '../assets/videos/Cust1_vid3.mp4';
import video4 from '../assets/videos/Cust1_vid4.mp4';
import video5 from '../assets/videos/Cust2_vid1.mp4';
import video6 from '../assets/videos/Cust2_vid2.mp4';
import video7 from '../assets/videos/Cust2_vid3.mp4';
import video8 from '../assets/videos/Cust2_vid4.mp4';

export default {
  name: 'Main',
  components: { MonitorQuestion, SectionLayout },
  computed: {
    ...mapGetters(['currentQuestion', 'checkIfLastQuestion']),
  },
  data() {
    return {
      showQuestion: false,
      showResponse: false,
      videos1: [video3, video5, video7],
      videos2: [video4, video6, video8],
      videoIndex1: 0,
      videoIndex2: 0,
      selectedResponse: '',
      isLastVideo: false,
      currentVideo: video1,
      nextVideo: video2,
      showVideo1: true,
      showVideo2: false,
    };
  },
  mounted() {
    const vid1 = this.$refs.video1;
    vid1.play();
  },
  methods: {
    playVideo1() {
      this.showVideo2 = false;
      this.showVideo1 = true;

      const vid1 = this.$refs.video1;
      vid1.play();

      this.nextVideo = this.videos2[this.videoIndex2];
      this.videoIndex2 += 1;
    },
    playVideo2() {
      this.showVideo1 = false;
      this.showVideo2 = true;

      const vid2 = this.$refs.video2;
      vid2.play();

      this.currentVideo = this.videos1[this.videoIndex1];
      this.videoIndex1 += 1;
    },
    onVideoEnded() {
      if (this.checkIfLastQuestion) {
        const video = this.$refs.video2;
        video.pause();
        this.isLastVideo = true;
      } else if (this.videoIndex1 === 2 && this.videoIndex2 === 1) {
        this.playVideo1();
      } else {
        this.showQuestion = true;
      }
    },
    nextQuestion() {
      this.$store.dispatch('nextQuestion');
      this.$store.dispatch('setSelectedAnswer', '');
    },
    chooseAnswer(answer) {
      this.$store.dispatch('setSelectedAnswer', answer);
      this.selectedResponse = this.currentQuestion.responses[answer];

      setTimeout(() => {
        this.showQuestion = false;
        this.showResponse = true;
      }, 1000);
    },
    continueVideo() {
      if (this.showVideo1) {
        this.playVideo2();
      } else {
        this.playVideo1();
      }

      this.showResponse = false;
      this.nextQuestion();
    },
    exitHandler() {
      this.$router.push('/');
      this.$store.dispatch('resetQuestionIndex');
      this.$store.dispatch('resetCurrentQuestion');
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;

  height: 100%;
  overflow: hidden;

  background: #333;
}

.container {
  position: relative;

  display: flex;
  justify-content: center;
}

.video {
  z-index: 0;

  height: 100%;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.final {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
</style>
