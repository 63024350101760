<template>
  <section>
    <div class="inner">
      <section class="content">
        <h1 class="header">{{ title }}</h1>
        <p class="text">{{ text }}</p>
      </section>
      <Button @action='$emit("btn-action")' size="lg">{{ btnText }}</Button>
    </div>
  </section>
</template>

<script>
import Button from './Button.vue';

export default {
  name: 'SectionLayout',
  props: ['title', 'text', 'btnText'],
  components: { Button },
};
</script>

<style scoped>

.inner {
  width: 100%;
  max-width: 90rem;
  padding-inline: 1.5rem;
  margin-inline: auto;
}

.inner button {
  margin-inline: auto;
}

.content {
  text-align: center;

  margin-bottom: 9.2rem;
}

.header {
  margin-bottom: 6rem;

  font-family: 'Helvetica Bold', sans-serif;
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.42;
}

.text {
  font-size: 4.5rem;
}
</style>
